<template>
  <div>
    <SpinnerLoadingModal
      v-if="isLoadingFirmware && this.sourceData.entityInfo.status !== 'ready'"
      size="l"
      :text="$t('firmwareUpdateMessage')"
    />

    <p v-else-if="errorLoadEntity">
      {{ $t('errorLoadEntity') }}
    </p>
    <template v-else>
      <form v-if="!isLoading"
        class="pacs-container-entity"
        @keyup.enter="saveDataAndReload()"
        @submit.prevent="saveDataAndReload()"
      >
        <div class="pacs-entity-form-primary">
          <ViewTile
            v-for="cameraInfo in listCamerasInfo"
            :key="cameraInfo.number"
            :camera-info="cameraInfo"
            class="mesh-cameras-grid__cell"
          >
            {{ dataForm.cameraNumber }}
          </ViewTile>
          <div class="camera-info-box">
            <div><strong>{{ $t('cameraModel') }}:</strong> {{ dataForm.model }}</div>
            <div><strong>{{ $t('macAddress') }}:</strong> {{ dataForm.macAddress }}</div>
            <div>
              <strong>{{ $t('firmwareVersion') }}: {{ dataForm.firmWareVersion }}
                <CamsButton
                  v-if="this.sourceData.entityInfo.new_firmware_available"
                  type="button"
                  priority="primary"
                  size="s"
                  style="font-size: 9px"
                  @click="cameraUpgrade()"
                >
                  {{ $t('firmwareUpdateAvailable') }}
                </CamsButton></strong>
            </div>
            <div><strong>{{ $t('cameraNumber') }}:</strong> {{ dataForm.cameraNumber }}</div>
          </div>
        </div>
        <div class="pacs-entity-form-secondary" style="width: 400px">
          <SmartSwitch
            v-model="dataForm.videoEnable"
            :caption="$t('enableVideoStream')"
          />
          <SmartSwitch
            v-if="this.isSound"
            v-model="dataForm.audioEnable"
            :caption="$t('enableAudio')"
            class="setup-fields-item"
          />
          <SmartInputText
            v-model="dataForm.ntpServer"
            :error="dataErrors.ntpServer"
            :caption="$t('ntpServer')"
            maxlength="255"
            class="setup-fields-item"
          />
          <SmartSelect
            v-model="dataForm.videoResolution"
            width="fill"
            :options="this.videoResolutionOptions"
            :caption="$t('videoResolution')"
            size="m"
            class="setup-fields-item"
          />
          <SmartSelect
            v-model="dataForm.videoBitrate"
            :options="this.videoBitrateOptions"
            :caption="$t('videoBitrate')"
            size="m"
            width="fill"
            class="setup-fields-item"
          />
          <SmartSelect
            v-if="this.isNightMode"
            v-model="dataForm.nightModeEnable"
            width="fill"
            size="m"
            :options="this.nightModeOptions"
            :caption="$t('nightModeEnable')"
          />
          <SmartVSelect
            v-model="dataForm.timezone"
            :error="dataErrors.timezone"
            :caption="$t('timeZone')"
            maxlength="255"
            class="setup-fields-item"
            :settings-remote-search="settingsRemoteSearchTimezone"
          />
          <CamsButton
            priority="primary"
            class="setup-fields-item"
            type="submit"
            style="margin-left: 40px"
          >
            {{ $t('save') }}
          </CamsButton>
          <CamsButton
            priority="primary"
            type="button"
            style="margin-left: 20px"
            @click="cameraReboot()"
          >
            {{ $t('rebootCamera') }}
          </CamsButton>
        </div>
      </form>
    </template>
    <SpinnerLoadingModal v-if="isLoading" />
  </div>
</template>

<script>
import {editEntityMixin} from "@/components/pacs/mixins.js";
import {
  ACTION_EDIT_CAMERA_SETUP,
  ACTION_LOAD_CAMERAS_SETUP_EDIT, ACTION_LOAD_TIME_ZONE_FOR_SELECT,
  ACTION_REBOOT_CAMERA_SETUP, ACTION_UPGRADE_CAMERA_SETUP, VIDEO_BITRATE_OPTIONS,
  VIDEO_RESOLUTION_OPTIONS
} from "@/store/setup/index.js";
import ViewTile from "@/components/meshCameras/ViewTile.vue";
import {ACTION_LOAD_CAMERAS, FIELDS_CAMERA} from "@/store/cameras/index.js";

/**
 * Компонент страницы конфигурации камеры.
 */
export default {
  mixins: [editEntityMixin],
  components: {
    ViewTile
  },
  data() {
    const cameraNumber = this.$route.params.cameraNumber;
    return {
      entityId: cameraNumber,
      actionForSave: `setup/${ACTION_EDIT_CAMERA_SETUP}`,
      actionForLoadEntity: `setup/${ACTION_LOAD_CAMERAS_SETUP_EDIT}`,
      isLoadingFirmware: false,
      dataForm: {
        cameraNumber,
        model: "",
        ntpServer : "",
        videoBitrate: 0,
        videoResolution: this.videoResolution,
        cameraStatus: this.cameraStatus,
        timezone: this.timezone,
      },
      isSound: "",
      isNightMode: "",
      listCamerasInfo: [],
      videoResolutionOptions: VIDEO_RESOLUTION_OPTIONS,
      nightModeOptions: {
        "on": this.$t('on'),
        "off": this.$t('off'),
        "auto":this.$t('auto'),
      },
      videoBitrateOptions: VIDEO_BITRATE_OPTIONS,
      settingsRemoteSearchTimezone: {
        action: `setup/${ACTION_LOAD_TIME_ZONE_FOR_SELECT}`,
        valueField: "value",
        labelField: "value",
        searchParams: "search",
      },
    };
  },
  methods: {
    async afterLoadSourceData() {
      this.dataForm = {
        ...this.dataForm,
        ntpServer: this.sourceData.entityInfo.ntp_server,
        audioEnable: this.sourceData.entityInfo.audio_enable,
        model: this.sourceData.entityInfo.model,
        nightModeEnable: this.sourceData.entityInfo.night_mode_enable,
        timezone: this.sourceData.entityInfo.timezone,
        videoBitrate: this.sourceData.entityInfo.video_bitrate,
        videoEnable: this.sourceData.entityInfo.video_enable,
        macAddress: this.sourceData.entityInfo.mac,
        firmWareVersion: this.sourceData.entityInfo.firmware_version,
        videoResolution: this.sourceData.entityInfo.video_resolution,
        cameraStatus: this.sourceData.entityInfo.status,
        modelCapabilities: this.sourceData.entityInfo.model_capabilities,
      };
      this.isSound = this.dataForm.modelCapabilities.is_sound
      this.isNightMode = this.dataForm.modelCapabilities.is_night_mode
      this.listCamerasInfo = [];
      if (!_.isEmpty(this.dataForm.cameraNumber)) {
        this.listCamerasInfo = await this.$store.dispatch(`cameras/${ACTION_LOAD_CAMERAS}`, {
          fields: [
            FIELDS_CAMERA.number,
            FIELDS_CAMERA.address,
            FIELDS_CAMERA.title,
            FIELDS_CAMERA.timezone,
            FIELDS_CAMERA.analytics,
            FIELDS_CAMERA.is_public,
            FIELDS_CAMERA.inactivity_period,
            FIELDS_CAMERA.server,
            FIELDS_CAMERA.token_l,
            FIELDS_CAMERA.tariff,
            FIELDS_CAMERA.permission
          ],
          numbers: [this.dataForm.cameraNumber],
        });
        if (this.dataForm.cameraStatus !== "ready") {
          setTimeout(()=>{
            this.loadSourceData();
            this.isLoadingFirmware = true;
          }, 15000);
        }
      }
    },
    async saveDataAndReload() {
      try {
        await this.saveData();
        await this.loadSourceData();
        this.$notify({
          group: "main",
          text: this.$t('saveSuccess'),
          duration: 5000,
          type: "success"
        });
      } catch(dataErrors) {
        this.$notify({
          group: "main",
          text: this.$t('saveError'),
          duration: 5000,
          type: "error"
        });
      }
    },
    cameraReboot() {
      this.$camsdals.confirm(this.$t('confirmReboot'), async () => {
        this.isLoading = true;
        try {
          await this.$store.dispatch(`setup/${ACTION_REBOOT_CAMERA_SETUP}`, this.dataForm.cameraNumber);
          this.$notify({
            group: "main",
            text: this.$t('cameraRebootIn15Sec'),
            duration: 5000,
            type: "success"
          });
        } catch {
          this.$notify({
            group: "main",
            text: this.$t('rebootError'),
            duration: 5000,
            type: "error"
          });
        }
        this.isLoading = false;
      });
    },
    cameraUpgrade() {
      this.$camsdals.confirm(this.$t('confirmFirmwareUpgrade', {version: this.sourceData.entityInfo.new_firmware_available}), async () => {
        this.isLoadingFirmware = true;
        try {
          await this.$store.dispatch(`setup/${ACTION_UPGRADE_CAMERA_SETUP}`, this.dataForm.cameraNumber);
          this.$notify({
            group: "main",
            text: this.$t('firmwareUpgradeIn45Sec'),
            duration: 5000,
            type: "success"
          });
        } catch {
          this.$camsdals.alert(this.$t('firmwareUpgradeError'));
        }
        this.loadSourceData();
      });
    },
  },
};
</script>

<style lang="scss">
.camera-info-box {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  padding: 15px;
  margin-bottom: 10px;
  gap: 20px;
  height: 118px;
  background: #FFFFFF;
  box-shadow: 0 3px 7px -3px rgba(24, 39, 75, 0.12), 0px 5px 16px -2px rgba(24, 39, 75, 0.1);
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
</style>
